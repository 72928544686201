import React from 'react';
import { FlwContext } from '../context/FlwContext';
import { getQweryParams,getAppLanguage } from '../services';
import { useLocation, useNavigate } from 'react-router-dom';
import { get, getCookie, logError, setCookie } from '../utils/network';
import { AxiosResponse } from 'axios';
import { ValidationResponse } from '../models/Login';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { v4 } from 'uuid';
import dayjs from 'dayjs';

import '../App.scss';

const VerifyComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [params] = React.useState(getQweryParams());
    const {state: appContext} = React.useContext(FlwContext);
    appContext.lang = getAppLanguage(location.pathname);
    const translation = appContext.translation;

    const uuid = React.useRef( getCookie('loginUUID') || v4() );
    const logTries = React.useRef( 0 );

    const domain = window.location.hostname .toLocaleLowerCase() .split('.') .slice(1, 10) .join('.');
    const isTestEnv = window.location.hostname.includes('.test') || window.location.hostname.includes('localhost');


    /**
     * Validate the email link on page load
     * And then call the polling endpoint just once
     * If error navigate to login page with invalid param to enter invalid token flow
     */
    React.useEffect(() => {
        get(`/api/v1/flw/auth/isValidLink?email=${params.email}&uuid=${params.uuid}&signature=${params.signature}&timestamp=${params.timestamp}&domain=${params.domain}&userName=${params.userName}&displayName=${params.displayName}`)
        .then((response: any) => {
            if(response.data === true) {
                setCookie('loginUUID', uuid.current, 'flexwhere.com', 30);

                const originDeviceId = params?.deviceId;
                if(originDeviceId && originDeviceId !== uuid.current) {
                    const isSamePlatform = params?.source === 'web';
                    if(logTries.current === 0) {
                        logTries.current++;
                        logError(originDeviceId, uuid.current, `${isSamePlatform ? '' : `Different browser ${params.source},`} user ${params.email}, domain ${params.domain}, browser: ${getBrowser()}`);
                    }
                }
                
                const deviceId = originDeviceId ?? uuid.current;
                validateID(`/api/v1/flw/auth/${params.uuid}/isValidId/?deviceId=${deviceId}`);
            }
        })
        .catch(invalidToken);
    })

    /**
     * The same polling endpoint called just once after validating the email link
     * And then redirect to org page
     * @param url 
     */
    const validateID = (url: string) => {
        get(url)
        .then((response: AxiosResponse<ValidationResponse>) => {
            if(response){
                const data = response.data;
                setCookie(
                    `flexwhereAccessToken_${data.subDomain}`,
                    encodeURIComponent(data.authToken),
                    domain,
                    1
                );
                setCookie('lastLogin', dayjs().toISOString(), 'flexwhere.com', 90);
                // setCookie(
                //     `accessToken${isTestEnv ? '_test' : ''}`, 
                //     data.authToken,
                //     'flexwhere.com',
                //     28 
                // );

                localStorage.setItem('lastLogin', JSON.stringify(data));

                const ONE_YEAR = 365;
                setCookie( `flexwhereSubDomain`, data.subDomain, domain, ONE_YEAR );

                window.location.href = `${window.location.protocol}//${window.location.hostname.replace('web', data.subDomain)}`;
            }
        })
        .catch(invalidToken)
    }

    const getBrowser = () => {
        let userAgent = navigator.userAgent;
        let browserName = "Unknown";
        if (userAgent.indexOf("Firefox") !== -1) {
            browserName = "Mozilla Firefox";
        } else if (userAgent.indexOf("Edg") !== -1) {
            browserName = "Microsoft Edge";
        } else if (userAgent.indexOf("Chrome") !== -1) {
            browserName = "Google Chrome";
        } else if (userAgent.indexOf("Safari") !== -1) {
            browserName = "Apple Safari";
        } else if (userAgent.indexOf("Opera") !== -1 || userAgent.indexOf("OPR") !== -1) {
            browserName = "Opera";
        } else if (userAgent.indexOf("Trident") !== -1 || userAgent.indexOf("MSIE") !== -1) {
            browserName = "Internet Explorer";
        }
        return browserName;
    } 

    const invalidToken = () => {
        appContext.invalid = true;
        navigate(`/${appContext.lang}/invalid`)
    }

    return(
        <React.Fragment>
            <Container className='verify' component={'section'}>
                <img src={process.env.PUBLIC_URL+'/flexwhere.svg'} alt='FlexWhere' />
                <Container component="div" style={{marginTop: 60}}>
                    <CircularProgress />
                </Container>
                <p className='link'>{translation?.login?.loginCheckSupport} <a href="https://support.flexwhere.com" target="_blank" rel="noreferrer">support.flexwhere.com</a></p>
            </Container>
        </React.Fragment>
    )
}

export const Verify = VerifyComponent;
