import { AxiosResponse } from "axios";
import { post, setAuthToken, setCookie } from "../utils/network";
import { SSOResponse, SSOResponseBody } from "../models/Login";

export const acquireTokenFromFlexwhere = (source: 'microsoft' | 'google' | 'apple', token: string, deviceUUID: string) => {
    const body = {
        platform: 'web',
        source: source,
        deviceId: deviceUUID,
        token: token,
        bundle: ''
    };
    return post('/api/v1/flw/auth/ssoLogin', body);
}

export const DOMAIN = window.location.hostname .toLocaleLowerCase() .split('.') .slice(1, 10) .join('.') ||  'test.flexwhere.com';

export const openFlexwhere = (data: SSOResponseBody) => {
    setAuthToken(
        `flexwhereAccessToken_${data.subDomain}`,
        encodeURIComponent(data.token),
        DOMAIN
    );
    const ONE_YEAR = 365;

    //Set the subdomain cookie for redirection
    setCookie(`flexwhereSubDomain`, data.subDomain, DOMAIN, ONE_YEAR);

    // localStorage.setItem('lastLogin', JSON.stringify(data));

    //Redirect to the subdomain
    //By replacing web if it exists in the URL
    gotoFlexWhere(data.subDomain);
}

/**
 * Navigates to FlexWhere org
 * @param subDomain the subdomain to navigate to
 */
const gotoFlexWhere = (subDomain: string) => {
    const proto = window.location.protocol;
    // instance.clearCache();
    const host = window.location.hostname.replace("web", subDomain);
    window.location.href = `${proto}//${host}`;
}